import React from 'react';
import PropTypes from 'prop-types';
import FleetTechnicalSupportContactInfo from '../../utils/fleet-technical-support-contact-info';

const FleetTechnicalSupportEmail = ({ interactive }) => {
  const email = FleetTechnicalSupportContactInfo.Email;
  if (!interactive) {
    return <span>{email}</span>;
  }
  return <a href={`mailto:${email}`}>{email}</a>;
};

FleetTechnicalSupportEmail.defaultProps = {
  interactive: true,
};

FleetTechnicalSupportEmail.protoTypes = {
  interactive: PropTypes.bool,
};

export default FleetTechnicalSupportEmail;
