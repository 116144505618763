import React, { createContext, useEffect, useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { getOperationName } from '@apollo/client/utilities';

const FeatureContext = createContext();
const useFeatureContext = () => useContext(FeatureContext);

const FeatureProvider = ({ children, featureQuery }) => {
  const [featureError, setFeatureError] = useState(null);
  const operationName = getOperationName(featureQuery);
  const [
    getFeature,
    { data: featureData, loading: isFeatureLoading },
  ] = useLazyQuery(featureQuery, {
    fetchPolicy: 'network-only',
    onError(err) {
      setFeatureError(err);
    },
  });

  useEffect(() => {
    getFeature();
  }, []);

  return (
    <FeatureContext.Provider
      value={{ featureError, featureData, isFeatureLoading, operationName }}
    >
      {children}
    </FeatureContext.Provider>
  );
};

FeatureProvider.propTypes = {
  children: PropTypes.element.isRequired,
  featureQuery: PropTypes.shape(Object).isRequired,
};

export { FeatureProvider, useFeatureContext };
