import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { logout } from '../utils/auth-services';
import { Modal, connectModal, useModal, Button } from '@gsa/afp-component-library';
import {
  useUserSessionStatus,
  useUserInactivity,
  useCurrentUser
} from '../hooks';
import { sessionStatus } from '../hooks/use-user-session-status';

const Title = () => {
  return <h2>Are you still with us?</h2>;
};

const Content = () => {
  const { getCurrentStatus } = useUserSessionStatus();
  const { statusTimestamp } = getCurrentStatus();
  const duration = 5 - Math.floor((Date.now() - statusTimestamp)/60000);
     
  return (
    <p>
      For your security, your session is about to expire in <b>{duration} minutes</b> due to
      inactivity. If you select the &lsquo;Log out&rsquo; button or do not
      respond, your session will automatically close. Do you want to stay signed
      in?
    </p>
  );
};

const Actions = ({ onCancel, onConfirm }) => {
  return (
    <React.Fragment>
      <Button
        label="Log out"
        variant="unstyled"
        className="padding-right-1"
        type="button"
        onClick={onCancel}
        data-testid="session-logout-btn"
      />
      <Button
        label="Yes, keep me signed in"
        variant="primary"
        type="button"
        data-testid="session-keep-signed-btn"
        onClick={onConfirm}
      />
    </React.Fragment>
  );
};

Actions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const PromptSessionExpiring = () => {
  const { isLoggedIn } = useCurrentUser();
  const { isOpen, openModal, closeModal } = useModal();
  const {
    setExpiring,
    setLoggedIn,
    onExpiring,
    onLoggedIn,
    stopSessionEventListener,
    observeSessionStatus,
    getCurrentStatus
  } = useUserSessionStatus();
  const { startIdleTimer, pauseIdleTimer } = useUserInactivity({
    onIdle: onUserIdle
  });

  function onUserIdle() {
    setExpiring();
  }

  const onCancel = () => {
    closeModal();
    logout();
  };

  const onConfirm = () => {
      closeModal();
      setLoggedIn();
  };

  
  let expiringTimeout = 5 * 1000 * 60; // 5 minutes
  if (window.AFP_CONFIG && 
      window.AFP_CONFIG.expiring_session_timer_minutes) {
      expiringTimeout = window.AFP_CONFIG.expiring_session_timer_minutes * 1000 * 60;
  }

  let expiringTimedoutHandlerId;
  const startExpiringTimeout = (handler, timeout) => {
    return setTimeout(handler, timeout);
  };
  const stopExpiringTimeout = (id) => {
    clearTimeout(id);
  };

  let expiringHandlerId;
  let loggedInHandlerId;
  const handleExpiring = () => {
    pauseIdleTimer();
    const { statusTimestamp } = getCurrentStatus();
    const customTimeout = Date.now() - statusTimestamp;

    if (customTimeout > (expiringTimeout - (1000 * 30))) {
      logout();
    } else {
      openModal();
      if(!expiringTimedoutHandlerId) {
        expiringTimedoutHandlerId = startExpiringTimeout(onCancel, expiringTimeout - customTimeout);
      }
    }
  };
  const handleLoggedIn = () => {
    startIdleTimer();
    closeModal();
    stopExpiringTimeout(expiringTimedoutHandlerId);
    expiringTimedoutHandlerId = undefined;
  };

  useEffect(() => {
    if (isLoggedIn) {
      expiringHandlerId = onExpiring(handleExpiring);
      loggedInHandlerId = onLoggedIn(handleLoggedIn);

      const { status } = getCurrentStatus();
      if (!status) {
        logout();
      }

      if (status === sessionStatus.expiring) {
        handleExpiring();
      } else {
        setLoggedIn();
        observeSessionStatus();
      }

      return () => {
        stopSessionEventListener(expiringHandlerId, sessionStatus.expiring);
        stopSessionEventListener(loggedInHandlerId, sessionStatus.loggedIn);
      };
    }
  }, [isLoggedIn]);

  const PromptModal = connectModal(() => (
    <Modal
      className="usa-prose"
      variant="large"
      title={<Title />}
      actions={
        <Actions 
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      }
      onClose={onCancel}
    >
      <Content />
    </Modal>
  ));

  return <PromptModal isOpen={isOpen} />;
};

export default PromptSessionExpiring;