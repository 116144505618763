import React from 'react';
import PropTypes from 'prop-types';
import FleetTechnicalSupportContactInfo from '../../utils/fleet-technical-support-contact-info'

const FleetTechnicalSupportPhone = ({ interactive, ...props }) => {
    const phone = FleetTechnicalSupportContactInfo.Phone;
    if (!interactive) {
        return <span {...props}>{phone}</span>;
    }
    return <a {...props} href={`tel:${phone}`}>{phone}</a>;
}

FleetTechnicalSupportPhone.defaultProps ={
    interactive: true
};

FleetTechnicalSupportPhone.protoTypes ={
    interactive: PropTypes.bool
};

export default FleetTechnicalSupportPhone;