import { useEffect } from 'react';

// TODO: unit-tests
const useTitle = (title) => {
  const defaultSuffix = 'GSAFleet.gov';

  useEffect(() => {
    const formattedTitle = `${title} | ${defaultSuffix}`;

    if (title) {
      document.title = formattedTitle;
    }

    // reset the title back to default if the formatted title matches document title
    return () => {
      if (title && formattedTitle === document.title) {
        document.title = defaultSuffix;
      }
    };
  }, [title]);
};

export default useTitle;
