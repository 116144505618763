import { gql } from '@apollo/client';

export const navigationMenu = gql`
  fragment NavigationFields on NavigationMenu {
    title
    linkUrl
    order
    isHidden
    hasSubMenu
    permission {
      name
      operation {
        name
      }
      subject {
        name
      }
    }
    childNavigationItems {
      title
      linkUrl
      order
      isHidden
      permission{
        name
        operation {
          name
        }
        subject {
          name
        }
      }
    }
  }
`;

export const GET_NAVIGATION_MENUS = gql`
  query GetNavigationMenus {
    getNavigationMenus {
      ...NavigationFields
    }
  }
  ${navigationMenu}
`;
