import React, { Fragment } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

const FETCH_POLICY = {
  fetchPolicy: 'network-only', // Used for first execution
  nextFetchPolicy: 'cache-first', // Used for subsequent executions
};

export const IS_ORG_OPTED_IN = gql`
  query isOrgOptedIn(
    $agencyCode: String!
    $bureauCode: String
    $feature: String
  ) {
    isOrgOptedIn(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      feature: $feature
    )
  }
`;

// wrapper function to use lazy query to check org-opt-in feature
export function getIsOrgOptInLazyQuery(options) {
  return useLazyQuery(IS_ORG_OPTED_IN, {
    ...options,
    ...FETCH_POLICY,
  });
}

// UI wrapper to show/hide base on org-opt-in feature
export const OptIn = ({
  agencyCode,
  bureauCode,
  feature,
  children,
  isHidden,
  loadingContent,
  notOptInContent,
}) => {
  if (!agencyCode || isHidden) return null;

  const variables = { agencyCode };
  if (bureauCode) variables.bureauCode = bureauCode;
  if (feature) variables.feature = feature;

  const { loading, data } = useQuery(IS_ORG_OPTED_IN, {
    ...FETCH_POLICY,
    variables,
  });
  if (loading)
    return loadingContent ? <Fragment>{loadingContent}</Fragment> : null;
  if (data?.isOrgOptedIn) return <Fragment>{children}</Fragment>;
  return notOptInContent ? <Fragment>{notOptInContent}</Fragment> : null;
};

OptIn.defaultProps = {
  agencyCode: undefined,
  bureauCode: undefined,
  feature: undefined,
  children: null,
  isHidden: false,
  loadingContent: null,
  notOptInContent: null,
};
OptIn.propTypes = {
  agencyCode: PropTypes.string,
  bureauCode: PropTypes.string,
  feature: PropTypes.string,
  children: PropTypes.node,
  isHidden: PropTypes.bool,
  loadingContent: PropTypes.node,
  notOptInContent: PropTypes.node,
};
