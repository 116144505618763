import { Hub } from 'aws-amplify';
import {
    startPollingCookie,
    stopPollingCookie,
    setCookie,
    getCookie,
    deleteCookies
} from '../utils';

const authEventName = {
    signIn: 'signIn',
    signOut: 'signOut'
};
export const userSessionKey = 'fleet-user-session';

export const sessionStatus = {
    loggedOut: 'loggedOut',
    loggedIn: 'loggedIn',
    expiring: 'expiring'
};

export const getStatusParts = (currentStatus) => {
    let status;
    let statusTimestamp = 0;
    if (currentStatus) {
      const sessionStatusParts = currentStatus.split(',');
      status = sessionStatusParts[0];
      if (sessionStatusParts.length > 1) {
        statusTimestamp = sessionStatusParts[1];
      }
    }
  
    return { status, statusTimestamp };
  };

const listeners = {};

export default () => {
    const {
        loggedOut,
        loggedIn,
        expiring
    } = sessionStatus;

    const pipe = (...fns) => (withValue) =>
        fns.reduce((value, fn) => {
            if (fn) fn(value);
            return value;
        }, withValue);

    const cleanUpStorage = () => {
        deleteCookies(/^CognitoIdentityServiceProvider/);
    };

    const navigateToSignOutPath = () => {
        if (window.AFP_CONFIG
            && window.AFP_CONFIG.cognito_config
            && window.AFP_CONFIG.cognito_config
            && window.AFP_CONFIG.cognito_config.oauth
            && window.AFP_CONFIG.cognito_config.oauth.redirectSignOut) {

                if (window.location.href !== window.AFP_CONFIG.cognito_config.oauth.redirectSignOut) {
                    window.location.assign(window.AFP_CONFIG.cognito_config.oauth.redirectSignOut);
                }

        } else {
            throw new Error('Error window.AFP_CONFIG.cognito_config.oauth.redirectSignOut must be defined');
        }
    };

    const handleStatusChange = (newStatus) => {
        const { status } = getStatusParts(newStatus);
        let fns = listeners[status];
        if (fns) pipe(...fns)();

        if (status === loggedOut) {
            stopObserveSessionStatus();
            cleanUpStorage();
            navigateToSignOutPath();
        }
    };

    const observeSessionStatus = () => {
        startPollingCookie(userSessionKey, (status) => {
            handleStatusChange(status);
        });
    };

    const stopObserveSessionStatus = () => {
        stopPollingCookie();
    };

    const addListener = (status) => {
        return (fn) => {
            const fns = listeners[status] || [];

            let idx = fns.indexOf(fn);
            if (idx > -1) return idx;
            
            // reuse listeners status callbacks pool
            idx = fns.indexOf(undefined);
            if (idx > -1) {
                fns[idx] = fn;
            } else {
                idx = fns.push(fn) - 1;
            }

            listeners[status] = fns;

            return idx;
        };
    };

    const stopListener = (idx, status) => {
        const fns = listeners[status];
        if (!fns || !fns.length) return;

        fns.splice(idx, idx, undefined);
    };

    const setStatus = (status) => {
        return () => {
            if (status === expiring) {
                status = `${status},${Date.now()}`;
            }
            setCookie(userSessionKey, status);
            handleStatusChange(status);
        }
    };

    // FIX temporary check to resolve issue with home-app unit tests
    if (Hub) {
        Hub.listen('auth', ({payload}) => {
            const { event } = payload;
            const { signIn, signOut } = authEventName
            if (event === signIn) {
                setStatus(loggedIn)();
            }
            if (event === signOut) {
                setStatus(loggedOut)();
            }
        });
    }

    const getCurrentStatus = () => {
        return getStatusParts(getCookie(userSessionKey));
    };

    return {
        setLoggedIn: setStatus(loggedIn),
        setExpiring: setStatus(expiring),
        setLoggedOut: setStatus(loggedOut),
        onLoggedIn: addListener(loggedIn),
        onExpiring: addListener(expiring),
        onLoggedOut: addListener(loggedOut),
        stopSessionEventListener: stopListener,
        observeSessionStatus,
        stopObserveSessionStatus,
        getCurrentStatus,
    };
};