import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const focusElement = (selector, fallbackSelector) => {
  let element = null;
  if (selector !== null) {
    element = document.querySelector(selector);
  }

  if (element) {
    element.focus();
  } else if (fallbackSelector) {
    const fallbackElement = document.querySelector(fallbackSelector);
    if (fallbackElement) {
      fallbackElement.focus();
    } else {
      document.activeElement.blur();
    }
  } else {
    document.activeElement.blur();
  }
}

const PageFocus = ({ pageFocusSelector }) => {
  const { pathname, state } = useLocation();
  useEffect(() => {
    const selector = state?.focusSelector || pageFocusSelector?.[pathname] || null;
    focusElement(selector, 'h1[tabindex="-1"]');
  }, [pathname]);
  return null;
}

export default PageFocus;