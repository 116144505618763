import { useIdleTimer } from 'react-idle-timer'

const DEFAULT_EVENTS = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove'
    // 'visibilitychange'
  ]

export default ({onIdle} = {}) => {
    let timeout = 30 * 1000 * 60;
    if (window.AFP_CONFIG && 
        window.AFP_CONFIG.inactivity_timer_minutes) {
        timeout = window.AFP_CONFIG.inactivity_timer_minutes * 1000 * 60;
    }

    const handleOnIdle = evt => {
        if (onIdle) {
            onIdle();
        }
    };

    const { reset, start, pause } = useIdleTimer({
        timeout,
        events: DEFAULT_EVENTS,
        onIdle: handleOnIdle,
        debounce: 1000,
        startManually: true,
        stopOnIdle: true
    });

    return {
        startIdleTimer: start,
        resetIdleTimer: reset,
        pauseIdleTimer: pause
    };
};