import React from 'react';
import useCurrentUser from '../../hooks/use-current-user';
import { getHomeAppURL } from '../../utils/afp-config';
import FleetTechnicalSupportContact from '../fleet-technical-support/fleet-technical-support-contact';

const NotFound = () => {
  const { isLoggedIn } = useCurrentUser();
  const homeLink = {
    href: `${getHomeAppURL()}${isLoggedIn ? '/home' : ''}`,
    text: 'Go back to homepage',
  };
  return (
    <div className="grid-row grid-gap margin-b-5">
      <div className="grid-col-12 tablet:grid-col-8">
        <h1 className="font-sans-2xl">Sorry for the Detour</h1>
        <p className="font-body-lg">
          The page you tried to access may have gone offline or does not exist.
          Let&apos;s help you get back on the road.
        </p>

        <div className="margin-t-5">
          <ul className="usa-button-group">
            <li className="usa-button-group__item">
              <a href={homeLink.href} className="usa-button">
                {homeLink.text}
              </a>
            </li>
          </ul>
        </div>

        <p className="margin-bottom-4 margin-top-4">
          If you need assistance,
          <FleetTechnicalSupportContact />.
        </p>

        <p className="text-base">
          <strong>Error code:</strong> 404
        </p>
      </div>
      <div className="grid-col-12 tablet:grid-col-fill display-flex flex-row flex-justify-center padding-top-5">
        <svg
          aria-hidden="true"
          width="268"
          height="268"
          viewBox="0 0 268 268"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M126.363 3.30977C130.776 -1.10326 137.931 -1.10326 142.344 3.30977L264.69 125.656C269.103 130.069 269.103 137.224 264.69 141.637L141.637 264.69C137.224 269.103 130.069 269.103 125.656 264.69L3.30978 142.344C-1.10326 137.931 -1.10326 130.776 3.30977 126.363L126.363 3.30977Z"
            fill="#FEC31F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M256.661 133.1L135.448 12.4024C135.133 12.0883 134.621 12.0883 134.306 12.4024L12.427 133.763C12.1116 134.077 12.1116 134.586 12.427 134.9L133.64 255.598C133.956 255.912 134.467 255.912 134.783 255.598L256.661 134.237C256.977 133.923 256.977 133.414 256.661 133.1ZM139.447 8.42126C136.923 5.90841 132.831 5.90841 130.308 8.42126L8.42883 129.782C5.90524 132.294 5.90523 136.369 8.42883 138.881L129.642 259.579C132.166 262.092 136.257 262.092 138.781 259.579L260.66 138.218C263.183 135.706 263.183 131.631 260.66 129.119L139.447 8.42126Z"
            fill="#010101"
          />
          <path
            d="M85.1773 106.23C85.1773 78.848 107.209 56.6504 134.387 56.6504H135.994C163.171 56.6504 185.203 78.848 185.203 106.23V201.545H158.489V107.04C158.489 94.075 148.058 83.5651 135.19 83.5651C122.322 83.5651 111.891 94.075 111.891 107.04V145.056L126.902 142.655C129.824 142.188 131.913 145.43 130.3 147.928L115.285 171.19H115.431L98.5801 197.295L96.1804 193.525C95.9561 193.322 95.7532 193.084 95.5792 192.811L67.0044 147.919C65.4111 145.415 67.5075 142.192 70.4215 142.665L85.1773 145.059V106.23Z"
            fill="#010101"
          />
        </svg>
      </div>
    </div>
  );
};

export default NotFound;
