import { gql, useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { renderSubComponent } from './tag-lookup';
import { getVmsUrl } from '../../utils/afp-config';

export const GET_VEHICLES_BY_PARTIAL_VIN = gql`
  query GetVehiclesByPartialVin($partialVin: String!) {
    getVehiclesByPartialVin(partialVin: $partialVin) {
      id: vin
      makeColor {
        name
      }
      modelYear
      tagNumber
      vehicleMake {
        makeName
      }
      vehicleModel {
        modelDescription: modelName
      }
    }
  }
`;

function VINLookup({ onSelect, disabled, handleError }) {
  const [getVehiclesByPartialVin, { data }] = useLazyQuery(
    GET_VEHICLES_BY_PARTIAL_VIN,
    {
      context: {
        clientName: 'vms',
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        // eslint-disable-next-line no-console
        console.warn(err);
        handleError('Technical error occurred');
      },
    },
  );

  const [typeaheads, setTypeaheads] = useState([]);
  const [typeaheadValues, setTypeaheadValues] = useState([]);

  useEffect(() => {
    if (data) {
      setTypeaheads(data?.getVehiclesByPartialVin);
      handleError('');
    }
  }, [data]);

  useEffect(() => {
    const ids = [];
    if (typeaheads && typeaheads.length > 0) {
      typeaheads.forEach((item) => {
        ids.push(item.id);
      });
    }
    setTypeaheadValues(ids);
    // route to vehicle details if only one matching result
    if (typeaheads?.length === 1) {
      onSelect
        ? onSelect(typeaheads[0].id)
        : (window.location.href = `${getVmsUrl()}/vehicles/${encodeURIComponent(
            typeaheads[0].id,
          )}/overview`);
    }
  }, [typeaheads]);

  const fetchVehicles = (_, val) => {
    if (val) {
      getVehiclesByPartialVin({
        variables: { partialVin: val },
      });
    }
  };

  const handleSelect = (vin) => {
    if (vin) {
      onSelect
        ? onSelect(vin)
        : (window.location.href = `${getVmsUrl()}/vehicles/${encodeURIComponent(
            vin,
          )}/overview`);
    }
  };

  const handleClear = () => {
    onSelect && onSelect('');
  };

  return (
    <Typeahead
      id="vin"
      filterValue=""
      fetchTypeaheadValues={fetchVehicles}
      typeaheadValues={typeaheadValues}
      placeholder="Enter VIN"
      inputCharNum={6}
      ariaLabel="Enter VIN"
      debounceDelay={500}
      promptText="Search requires 6 characters"
      noResultsText="No results found. The VIN number does not exist or you do not have permission to access the vehicle."
      generateCustomOption={(vin) => renderSubComponent(typeaheads, vin, 'vin')}
      onOptionEnter={handleSelect}
      disabled={disabled}
      onClear={handleClear}
    />
  );
}

VINLookup.propTypes = {
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  handleError: PropTypes.func,
};
VINLookup.defaultProps = {
  onSelect: undefined,
  disabled: false,
};

export default VINLookup;
