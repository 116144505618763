import React from 'react';
import PropTypes from 'prop-types';
import UserProvider from './user-provider';
import AbilityProvider from './ability-provider';
import PromptSessionExpiring from '../components/prompt-session-expiring';

const withPromptSessionExpiring = (component) => {
    return (
    <React.Fragment>
        {component}
        <PromptSessionExpiring />
    </React.Fragment>);
};

const AppProvider = ({ children }) => {
    return (
            <UserProvider>
                <AbilityProvider>
                    { withPromptSessionExpiring(children) }
                </AbilityProvider>
            </UserProvider>
    );
};
  
AppProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

export default AppProvider;

