const UserStatus = {
    PendingProfile: 1,
    PendingRole: 2,
    Active: 3,
    Deactivated: 4,
    Denied: 5,
    Deleted: 6,
    PendingReactivation: 7,
    Inactive: 8
}

export default UserStatus;