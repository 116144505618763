// import useCurrentUser from '../use-current-user';
import { useFeatureContext } from '../../context/feature-provider';

const success = {
  errorByValidation: false,
  isLoadingByValidation: false,
  notFoundByValidation: false,
};

const validationResult = {
  notFound: { ...success, notFoundByValidation: true },
  hasError: { ...success, errorByValidation: true },
  onLoading: { ...success, isLoadingByValidation: true },
  success,
};

const validateFeature = ({
  featureName,
  featureData,
  operationName = '',
  featureError
}) => {
  if (featureError) {
    return validationResult.hasError;
  }
  
  const {
    [operationName]: getEnabledFeatures,
  } = featureData || {};

  if (getEnabledFeatures && !getEnabledFeatures.includes(featureName)) {
    return validationResult.notFound;
  }

  return featureData ? validationResult.success : validationResult.onLoading;
};

const useValidateProcess = ({ featureName }) => {
  // This might potentially violate the hook rule
  if (!featureName) {
    return validationResult.success;
  }

  const {
    featureError,
    featureData,
    isFeatureLoading,
    operationName
  } = useFeatureContext();

  if (featureName && isFeatureLoading) return validationResult.onLoading;
  return validateFeature({
    featureName,
    featureData,
    operationName,
    featureError,
  });
};

export { useValidateProcess, validationResult };
