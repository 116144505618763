import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@gsa/afp-component-library';
import FleetTechnicalSupportContact from '../fleet-technical-support/fleet-technical-support-contact';

const InternalError = (props) => {
  const { id = 'internal-error' } = props;
  return (
    <Alert type="error" data-testid={id} heading="System error" focused>
      <p className="desktop:grid-col-8">
        Please try again later. If you continue to experience technical
        difficulties with this page, <FleetTechnicalSupportContact />.
      </p>
    </Alert>
  );
};

InternalError.defaultProps = {
  id: 'internal-error',
};
InternalError.propTypes = {
  id: PropTypes.string,
};

export default InternalError;
