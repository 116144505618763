import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useTitle } from '../hooks';

const PublicRoute = ({
  component: Component,
  title,
  ...rest
}) => {
  
  useTitle(title);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

PublicRoute.defaultProps = {
  title: undefined,
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  title: PropTypes.string,
};

export default PublicRoute;
