import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import FleetTechnicalSupportContact from '../fleet-technical-support/fleet-technical-support-contact';

const Unauthorized = () => {
  return (
    <section
      className="usa-header mobile-lg:grid-col-6 padding-bottom-6 "
      data-testid="access-denied-page-header"
    >
      <PageTitle title="Access denied" />
      <p>
        The page or item you are trying to access is restricted. If you need
        assistance, <FleetTechnicalSupportContact />.
      </p>
    </section>
  );
};

export default Unauthorized;
