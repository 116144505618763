/* eslint-disable react/jsx-fragments */
import React from 'react';
import FleetTechnicalSupportEmail from './fleet-technical-support-email';
import FleetTechnicalSupportPhone from './fleet-technical-support-phone';

const FleetTechnicalSupportContact = () => {
  return (
    <React.Fragment>
      please contact the GSA Fleet Technical Support team at{' '}
      <FleetTechnicalSupportEmail /> or <FleetTechnicalSupportPhone /> from 8:00
      a.m. - 7:00 p.m. ET, Monday&ndash;Friday
    </React.Fragment>
  );
};
export default FleetTechnicalSupportContact;
