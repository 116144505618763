import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Spinner } from '@gsa/afp-component-library';
import useCurrentUser from '../hooks/use-current-user';
import useAppAbility from '../hooks/use-app-ability';
import UserStatus from '../utils/user-status';
import { getHomeAppURL } from '../utils/afp-config';
import { useTitle } from '../hooks';
import { defaultLoggedOutUserPath } from '../context/user-provider';
import { useValidateProcess } from '../hooks/use-validate-process/use-validate-process';
import { setCookie, getCookie, deleteCookieWithOptions } from '../utils';

const PrivateRoute = ({
  component: Component,
  loggedOutUserPath,
  operation,
  subject,
  title,
  featureName,
  ...rest
}) => {
  const {
    isLoaded,
    isLoggedIn,
    isLoading,
    authError,
    currentUser,
  } = useCurrentUser();
  const location = useLocation();
  const ability = useAppAbility();
  const validationProcess = useValidateProcess({ featureName });

  useTitle(title);

  const isPage = (pageType) =>
    location.pathname.toLowerCase().indexOf(pageType.toLowerCase()) > 0;

  const spinnerResponse = <Spinner className="padding-y-9" />;

  const signInURL = getHomeAppURL();
  const forwardUrlKey = 'forwardUrl';
  const forwardUrl = getCookie(forwardUrlKey);

  if (!getHomeAppURL) {
    throw new Error(' Home App URL needs to be provided');
  }

  useEffect(() => {
    // Redirect to forward URL if exist
    if (isLoggedIn && forwardUrl) {
      deleteCookieWithOptions(forwardUrlKey);
      window.location = decodeURIComponent(forwardUrl);
    }
  }, [isLoggedIn]);

  // TODO : Need to decide if there is app level logout or go to home-app logout
  const navigatetoLogout = () => window.location.replace(loggedOutUserPath);

  /**
   * Private checks for the following in this order
   *  1) Authentication
   *  2) User Status checks
   *  3) Feature Toggle
   *  4) Authorization
   *
   **/

  // Authentication checks
  // User is logged out. No active cognito session
  if (authError?.code === 'NOSESSION') {
    // Store forward URL to redirect to it after authentication
    const forwardUrl = window.location.href;
    if (forwardUrl) {
      setCookie(forwardUrlKey, forwardUrl);
    }
    // Redirect to public home page
    if (signInURL) {
      window.location.replace(signInURL);
      return spinnerResponse;
    }
    navigatetoLogout();
    return spinnerResponse;
  }

  // Redirects to unauthorized page if there is a GraphQL 'UNAUTHENTICATED' error.
  if (authError?.code === 'UNAUTHENTICATED') {
    // Redirect to unauthorized page
    return <Redirect to={{ pathname: '/unauthorized' }} />;
  }

  if (!isLoaded) {
    return spinnerResponse;
  }

  // TODO: not sure why we need to condition
  if (isLoaded && !isLoggedIn) {
    navigatetoLogout();
    return spinnerResponse;
  }

  if (isLoading || !ability) return spinnerResponse;

  if (!currentUser || !currentUser.status || !currentUser.status.id) {
    return <Redirect to={{ pathname: '/unauthorized' }} />;
  }

  // Status Checks
  const userStatus = parseInt(currentUser?.status?.id);
  switch (userStatus) {
    case UserStatus.Active:
      break;
    case UserStatus.PendingReactivation:
      if (!isPage('request-submitted'))
        return <Redirect to={{ pathname: '/request-submitted' }} />;
      break;
    case UserStatus.Inactive:
      if (!isPage('inactive'))
        return <Redirect to={{ pathname: '/inactive' }} />;
      break;
    case UserStatus.PendingProfile:
    case UserStatus.PendingRole:
      if (!isPage('profile')) return <Redirect to={{ pathname: '/profile' }} />;
      break;
    case UserStatus.Deleted:
    case UserStatus.Denied:
    case UserStatus.Deactivated:
    default:
      return <Redirect to={{ pathname: '/unauthorized' }} />;
  }

  // Feature Check

  const {
    isLoadingByValidation,
    errorByValidation,
    notFoundByValidation,
  } = validationProcess;
  if (errorByValidation) {
    return <Redirect to={{ pathname: '/internal-error' }} />;
  }
  if (notFoundByValidation) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  if (isLoadingByValidation) {
    return <Spinner className="padding-y-9" />;
  }

  // Authorization
  if (operation && subject) {
    if (!ability.can(operation, subject)) {
      return <Redirect to={{ pathname: '/unauthorized' }} />;
    }
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

PrivateRoute.defaultProps = {
  loggedOutUserPath: defaultLoggedOutUserPath,
  operation: undefined,
  subject: undefined,
  title: undefined,
  featureName: null,
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  loggedOutUserPath: PropTypes.string,
  operation: PropTypes.string,
  subject: PropTypes.string,
  title: PropTypes.string,
  featureName: PropTypes.string,
};

export default PrivateRoute;
